<template>
  <v-card
    flat
    class='pa-3 mt-2'
  >
    <v-card-text>
      <image-upload v-model='image' :width='120' :height='120' @formData-updated='imageUploaded'
                    @file-removed='imageRemoved'></image-upload>
      <v-divider></v-divider>
      <v-form
        class='multi-col-validation mt-6' ref='form'>
        <v-row>
          <v-col
            cols='12'
            md='6'
          >
            <v-checkbox
              v-model='mayProvidePresent'
              label='Начисление подарочного товара при покупке'
              dense
              outlined
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="applianceType"
              :items="applianceTypesDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Приборы"
              clearable
              dense
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-carousel
      v-if='isCombo'
      hide-delimiters
      height='100%'
    >
      <v-carousel-item
        v-for='(slide, i) in slicedChild'
        :key='i'
      >
        <v-sheet
          height='100%'>
          <v-row>
            <v-col sm='6' v-if='slide.length >=1' cols='12'
                   style='padding-left: 5vw; padding-right: 5vw'>
              <dish-card :dish='slide[0]'></dish-card>
            </v-col>
            <v-col sm='6' v-if='slide.length >=2' cols='12'
                   style='padding-left: 5vw; padding-right: 5vw'>
              <dish-card :dish='slide[1]'></dish-card>
            </v-col>
            <v-col sm='6' v-if='slide.length >=3' cols='12'
                   style='padding-left: 5vw; padding-right: 5vw'>
              <dish-card :dish='slide[2]'></dish-card>
            </v-col>
            <v-col sm='6' v-if='slide.length >=4' cols='12'
                   style='padding-left: 5vw; padding-right: 5vw'>
              <dish-card :dish='slide[3]'></dish-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-divider></v-divider>

    <v-card-text class='py-9 px-8'>
      <div class='payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row'>
        <div class='mb-8 mb-sm-0'>
          <p class='mb-1'>
            {{ name }}
          </p>
          <p class='mb-1'>
            {{ recipe }}
          </p>
        </div>
        <div>
          <table>
            <tr>
              <td class='pe-6'>
                Цена:
              </td>
              <td>
                {{ price }}
              </td>
            </tr>
            <tr>
              <td class='pe-6'>
                Вес:
              </td>
              <td>
                {{ weight }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-card-text>

    <v-data-table v-if='schemas'
                  :headers='schemaHeaders'
                  :items='schemas.filter(x=>!x.isComboCollector)'
                  show-expand
                  :hide-default-footer='true'
    >
      <template #[`item.name`]='{item}'>
        {{ item.name }}
      </template>

      <template #[`item.upLimit`]='{item}'>
        {{ item.upLimit }}
      </template>

      <template #[`item.downLimit`]='{item}'>
        {{ item.downLimit }}
      </template>

      <template #[`item.freeCount`]='{item}'>
        {{ item.freeCount }}
      </template>

      <template #[`item.isChangingPrice`]='{item}'>
        <v-tooltip bottom>
          <template v-slot:activator='{ on, attrs }'>
            <v-icon
              size='18'
              :color='resolveSchemaIsChangingPriceIcon(item.isChangingPrice).variant'
            >
              {{ resolveSchemaIsChangingPriceIcon(item.isChangingPrice).icon }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>

      <template #expanded-item='{ headers, item }'>
        <td :colspan='headers.length'>
          <v-simple-table class='purchased-items-table'>
            <template v-slot:default>
              <thead>
              <tr>
                <th>
                  Наименование
                </th>
                <th>
                  Цена
                </th>
                <th>
                  Тип
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for='modifier in item.modifiers'
                :key='modifier.id'
              >
                <td class='text-no-wrap'>
                  {{ modifier.name }}
                </td>
                <td class='text-no-wrap'>
                  {{ modifier.price }}
                </td>
                <td class='text-no-wrap'>
                  {{ modifier.type }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <v-card-text>
      <v-form class='multi-col-validation mt-6' ref='form'>
        <v-row>
          <v-col cols='12'>
            <v-btn
              @click='saveData'
              color='primary'
              class='me-3 mt-4'
            >
              Сохранить
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              class='mt-4'
              type='reset'
              @click='cancel'
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiMinusBoxMultipleOutline,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiClose,
  mdiCloseCircleOutline,
} from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters, mapState } from 'vuex'
import { rules } from '@/helpers/validation'
import ImageUpload from '@/components/image-upload.vue'
import DishCard from './dish-card.vue'

export default {
  name: 'DishForm',
  components: { ImageUpload, DishCard },
  data: () => ({
    icons: {
      mdiCheck,
      mdiMinusBoxMultipleOutline,
      mdiClose,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
    },
    newImage: null,
    isImageDeleted: false,
    rules,
  }),
  computed: {
    ...mapGetters('dictionary', { applianceTypesDropDown: 'applianceTypesDropDown' }),
    slicedChild() {
      let result = this.child.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 4)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
      return result
    },
    ...mapState('dish/form', {
      schemas: 'schemas',
    }),
    ...mapGetters('dish/form', { child: 'child' }),
    ...mapFormHandlers('dish/form', [
      'id',
      'name',
      'price',
      'isCombo',
      'isDelivery',
      'recipe',
      'weight',
      'image',
      'mayProvidePresent',
      'applianceType',
    ]),
    schemaHeaders() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Верхний предел', value: 'upLimit' },
        { text: 'Нижний предел', value: 'downLimit' },
        { text: 'Бесплатное колличество', value: 'freeCount' },
        { text: 'Меняет цену', value: 'isChangingPrice' },
      ]
    },
  },
  async created() {
    const { id } = this.$route.params
    await this.fetchById(id)
    await this.fetchApplianceTypes()
  },
  watch: {
    isCombo: function(newValue) {
      if (newValue) {
        let schema = this.schemas === null ? null : this.schemas.find(x => x.isComboCollector)
        if (schema !== null && schema.modifierGroupDetailId !== null)
          this.getChild(schema.modifierGroupDetailId)
      }
    },
    '$route.params.id': {
      handler: function(newValue, oldValue) {
        if (oldValue !== undefined) {
          const { id } = this.$route.params
          this.fetchById(id)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions('dictionary', { fetchApplianceTypes: 'fetchApplianceTypes' }),
    imageUploaded(blob) {
      this.newImage = blob
      this.isImageDeleted = false
    },

    imageRemoved() {
      this.newImage = null
      this.isImageDeleted = true
    },
    resolveSchemaIsChangingPriceIcon(status) {
      if (status) return { variant: 'success', icon: mdiCheckCircleOutline }
      return { variant: 'error', icon: mdiCloseCircleOutline }
    },
    cancel() {
      this.resetForm()
      this.$router.back()
    },
    ...mapActions('dish/form', ['fetchById', 'uploadPhoto', 'getChild', 'removePhoto', 'update']),
    async saveData() {
      const { id } = this.$route.params
      if (this.isImageDeleted) {
        await this.removePhoto(id)
      }
      if (this.newImage) {
        let form = new FormData()
        form.append('file', this.newImage)
        form.append('dishId', id)
        await this.uploadPhoto(form)
      }
      await this.update({
        mayProvidePresent: this.mayProvidePresent,
        applianceType: this.applianceType,
        dishId: id,
      })
      this.$router.back()
    },
  },
}
</script>
