<template>
  <v-card class="d-flex align-center">
    <div class="d-flex justify-space-between flex-wrap flex-md-nowrap flex-column flex-md-row">
      <div class="mx-auto">
        <v-img
          max-width="220"
          :src="dish.image"
        ></v-img>
      </div>
      <v-divider :vertical="$vuetify.breakpoint.mdAndUp"></v-divider>
      <div>
        <v-card-title>
          {{ dish.name }}
        </v-card-title>
        <v-card-text>
          {{ dish.recipe }}
        </v-card-text>
        <v-card-text class="text--primary text-base">
          <span>Цена :</span> <span class="font-weight-bold">{{ dish.price }}</span>
        </v-card-text>
        <v-card-text class="text--primary text-base">
          <span>Вес :</span> <span class="font-weight-bold">{{ dish.weight }}</span>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between dense">
          <v-btn
            text
            color="primary"
            dark
            @click="onShowClick(dish.id)"
          >
            <v-icon>{{ icons.mdiEye }}</v-icon>
            <span class="ms-2">Просмотреть</span>
          </v-btn>
          <v-btn @click="onEditClicked(dish.id)" v-if="dish.isCustom" icon>
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiEye, mdiPencil } from '@mdi/js'

export default {
  name: 'DishCard',
  props: ['dish'],
  data: () => ({
    icons: {
      mdiEye,
      mdiPencil,
    },
  }),
  methods: {
    onShowClick(id) {
      this.$router.push({ name: 'menu-version-dish-form', params: { id: id } })
    },
    onEditClicked(id) {
      this.$emit('dish-edit-clicked', id)
    },
  },
}
</script>

